import { DateTime } from "luxon";

export function formatTimeInterval(startTime: string, endTime: string): string {
  const start = DateTime.fromISO(startTime);
  const end = DateTime.fromISO(endTime);
  if (start.hasSame(end, "day")) {
    return `${start.toFormat("EEE, DD, t")} - ${end.toFormat("t (ZZZZ)")}`;
  } else {
    return `${start.toFormat("EEE, DD, t")} - ${end.toFormat("EEE, DD, t (ZZZZ)")}`;
  }
}
