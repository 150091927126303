import styled from "@emotion/styled";
import { css } from "@emotion/react";

const getMargin = ({ size = 0 }) => css(size ? `${size}px` : "0");

const BannerText = styled.p`
  margin: ${getMargin};
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #4fc3f7;
  border-radius: 3px;
  padding: 9px 12px 9px 20px;
  width: 100%;
`;

const style = {
  Banner,
  BannerText,
};

export default style;
