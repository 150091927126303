import { formatTimeInterval } from "../../utils/format-time-interval";
import { Badge } from "@bphxd/ds-core-react";
import { Card, CardBody, CardImg } from "reactstrap";
import { LinkButton } from "../link-button";
import { EventDetails } from "../../views/home/data/event-info";

type Props = {
  eventDetails: EventDetails[];
};

export function EventCards({ eventDetails }: Props) {
  if (eventDetails.length === 0) {
    return null;
  }

  const events = eventDetails.filter((event) => {
    const now = new Date();
    return new Date(event.endDate) > now;
  });

  events.sort((a, b) => {
    return new Date(a.startDate).getTime() - new Date(b.startDate).getTime();
  });

  return (
    <div className="rounded-4 mx-auto d-flex gap-6" style={{ width: 965 }}>
      {events.slice(0, 2).map((event) => {
        return (
          <div key={event.slug} className="border rounded-5 w-50 no-underline">
            <Card className="rounded-5 h-100">
              <div className="position-relative">
                {event?.image ? (
                  <CardImg
                    top
                    src={event.image}
                    alt="event profile"
                    style={{
                      height: 330,
                      objectFit: "cover",
                      borderRadius: ".75rem .75rem 0 0",
                    }}
                  />
                ) : (
                  <div style={{ height: 330, backgroundColor: "#f9f9f9" }} />
                )}
                <Badge className="mt-5 ms-5 position-absolute top-0 start-0" color="dark">
                  Community Event
                </Badge>
              </div>
              <CardBody className="rounded-5 d-flex flex-column">
                <h5 className="mb-2 fw-normal">{event.name}</h5>
                <p className="fw-light mb-5">{formatTimeInterval(event.startDate, event.endDate)}</p>
                <div className="h-100 d-flex align-items-end">
                  <LinkButton
                    href={`/events/${event.slug}`}
                    name="homepage-event-card-clicked"
                    trackingEventProps={{
                      name: "homepage-event-card-clicked",
                      eventName: event.name,
                      eventSlug: event.slug,
                    }}
                    level="tertiary"
                    theme="standard"
                    rounded="pill"
                    size="lg"
                  >
                    See event
                  </LinkButton>
                </div>
              </CardBody>
            </Card>
          </div>
        );
      })}
    </div>
  );
}
