export type EventDetails = {
  slug: string;
  name: string;
  description: string;
  location: string;
  startDate: string;
  endDate: string;
  registrationLink: string;
  image?: string;
};

export const EVENT_INFO: EventDetails[] = [];
